/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import url('https://fonts.googleapis.com/css?family=Noto+Sans&display=swap');
@import "simplebar/dist/simplebar.css";
@import "~animate.css/animate.min.css";
@import '~ngx-toastr/toastr';

@font-face {
    font-family: 'EYInterstate';
    src: url('assets/fonts/EYInterstate-Bold.eot');
    src: url('assets/fonts/EYInterstate-Bold.eot?#iefix') format('embedded-opentype'), url('assets/fonts/EYInterstate-Bold.woff2') format('woff2'), url('assets/fonts/EYInterstate-Bold.woff') format('woff'), url('assets/fonts/EYInterstate-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'EYInterstate';
    src: url('assets/fonts/EYInterstate-Light.eot');
    src: url('assets/fonts/EYInterstate-Light.eot?#iefix') format('embedded-opentype'), url('assets/fonts/EYInterstate-Light.woff2') format('woff2'), url('assets/fonts/EYInterstate-Light.woff') format('woff'), url('assets/fonts/EYInterstate-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'EYInterstate';
    src: url('assets/fonts/EYInterstate-Regular.eot');
    src: url('assets/fonts/EYInterstate-Regular.eot?#iefix') format('embedded-opentype'), url('assets/fonts/EYInterstate-Regular.woff2') format('woff2'), url('assets/fonts/EYInterstate-Regular.woff') format('woff'), url('assets/fonts/EYInterstate-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'EYInterstate';
    src: url('assets/fonts/EYInterstate-Italic.eot');
    src: url('assets/fonts/EYInterstate-Italic.eot?#iefix') format('embedded-opentype'), url('assets/fonts/EYInterstate-Italic.woff2') format('woff2'), url('assets/fonts/EYInterstate-Italic.woff') format('woff'), url('assets/fonts/EYInterstate-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'irr-icons';
    src: url('assets/fonts/irr-icons.eot?54dfcf');
    src: url('assets/fonts/irr-icons.eot?54dfcf#iefix') format('embedded-opentype'), url('assets/fonts/irr-icons.ttf?54dfcf') format('truetype'), url('assets/fonts/irr-icons.woff?54dfcf') format('woff'), url('assets/fonts/irr-icons.svg?54dfcf#irr-icons') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'irr-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-corep-analytics:before {
    content: "\e918";
}

.icon-if-analytics:before {
    content: "\e919";
}

.icon-all-analytics:before {
    content: "\e917";
}

.icon-privacy-notice-mgmt:before {
    content: "\e916";
}

.icon-report-scheduler-mgmt:before {
    content: "\e915";
}

.icon-role-mgmt:before {
    content: "\e914";
}

.icon-approval-level-mgmt:before {
    content: "\e900";
}

.icon-data-admin:before {
    content: "\e901";
}

.icon-data-extraction:before {
    content: "\e902";
}

.icon-data-ingestion:before {
    content: "\e903";
}

.icon-data-load:before {
    content: "\e904";
}

.icon-data-prep:before {
    content: "\e905";
}

.icon-data-validation:before {
    content: "\e906";
}

.icon-download-audit:before {
    content: "\e907";
}

.icon-download-exception:before {
    content: "\e908";
}

.icon-error-report:before {
    content: "\e909";
}

.icon-period-on-analytics:before {
    content: "\e90a";
}

.icon-report-analytics:before {
    content: "\e90b";
}

.icon-report-dashboard:before {
    content: "\e90c";
}

.icon-reporting:before {
    content: "\e90d";
}

.icon-report-pack:before {
    content: "\e90e";
}

.icon-review-approval:before {
    content: "\e90f";
}

.icon-suites:before {
    content: "\e910";
}

.icon-template-validation:before {
    content: "\e911";
}

.icon-trend-analytics:before {
    content: "\e912";
}

.icon-user-mgmt:before {
    content: "\e913";
}

.icon-bundle-type-mgmt:before {
    content: "\e901";
}

.icon-report-bundling:before {
    content: "\e917";
}

/*Common Styles*/
html,
body {
    background-color: #333;
}

body {
    font-family: 'EYInterstate', 'Noto Sans', sans-serif;
    font-weight: normal;
    font-style: normal;
}

.content-background {
    height: 100%;
    position: fixed;
    width: 100%;
}

.h-100vh {
    height: 100vh;
}

.cursor-pointer {
    cursor: pointer;
}

button:focus,
a:focus,
input:focus,
textarea:focus,
select:focus {
    outline: 0 !important;
    box-shadow: none !important;
}

.select-arrow {
    position: relative;
}

.select-arrow>select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    padding-right: 25px;
    max-width: 75% !important;
    text-overflow: ellipsis;
}

.select-arrow::after {
    position: absolute;
    visibility: visible;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: "\f078";
    font-size: 18px;
    right: 7px;
    top: 7px;
}

.select-arrow>select:focus::-ms-value {
    color: #27acaa;
    background: #333;
}

.bootstrap-dropdown-menu .dropdown-menu {
    background-color: #000;
    padding: 8px 0;
    font-size: 12px;
}

.bootstrap-dropdown-menu .dropdown-item {
    padding: 0 15px;
    color: #c4c4cd;
    margin-bottom: 5px;
}

.bootstrap-dropdown-menu .ui-radiobutton {
    vertical-align: middle;
    border: 2px solid rgba(196, 196, 205, 0.36);
    border-radius: 50%;
    margin-right: 10px;
}

.bootstrap-dropdown-menu .dropdown-item:hover,
.bootstrap-dropdown-menu .dropdown-item:focus {
    background-color: #333;
}

.bootstrap-dropdown-menu .dropdown-item label {
    margin-bottom: 0;
    cursor: pointer;
}

.attribution-line {
    margin-bottom: 0;
    font-size: 8px;
    color: #999;
}

.bootstrap-radio .custom-control-input:checked~.custom-control-label::before {
    border-color: #27acaa;
    background-color: #27acaa;
}

/*Button Styles*/
.btn-primary-ey {
    background-color: #000;
    color: #fff;
    border: 2px solid rgba(204, 204, 204, 0.36);
    padding: 12px 30px;
    border-radius: 40px;
    text-transform: uppercase;
}

.btn-secondary-ey {
    background-color: #ffe600;
    color: #000;
    border: 0;
    padding: 12px 30px;
    border-radius: 40px;
    text-transform: uppercase;
}

.btn-secondary-ey.btn-icon .icon-container {
    background-color: #333;
    color: #ffe600;
}

.btn-icon {
    position: relative;
}

.btn-icon .icon-container {
    position: absolute;
    left: 5px;
    top: 5px;
    background-color: #ffe600;
    color: #000;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    font-size: 12px;
}

.btn-icon span {
    margin-left: 30px;
}

.btn-primary-ey:hover {
    color: #fff;
}

.small-font {
    font-size: 13px;
}

.font-14 {
    font-size: 14px;
}

.small-font .icon-container {
    left: 6px;
    top: 7px;
    width: 30px;
    height: 30px;
}

.hvr-shrink {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
}

.hvr-shrink:hover,
.hvr-shrink:focus,
.hvr-shrink:active {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
}

.hvr-grow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.hvr-bounce-in {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
}

.hvr-bounce-in:hover,
.hvr-bounce-in:focus,
.hvr-bounce-in:active {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
    transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

button.btn-table-icon:disabled {
    border: 2px solid #999;
    color: #999;
    pointer-events: none;
}

button.btn-table-icon {
    background-color: #2e2e38;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin-right: 15px;
    border: 2px solid #27acaa;
    color: #27acaa;
    box-shadow: 4px 4px 10px 4px rgba(0, 0, 0, 0.46)
}

.btn-icon-round {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-yellow {
    background-color: #ffe600;
    color: #000;
}

.btn-icon-round.dropdown-toggle::after {
    display: none;
}

.btn-simple,
.btn-simple:hover {
    background-color: transparent;
    color: #27acaa;
    border: 1px solid #27acaa;
    padding: 5px 15px;
    border-radius: 0;
}

.btn-modal-neg,
.btn-modal-neg:hover {
    padding: 10px 30px;
    border-radius: 20px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    color: #fff;
}

.btn-modal-pos,
.btn-modal-pos:hover {
    padding: 10px 30px;
    border-radius: 20px;
    border: 2px solid rgba(255, 230, 0, 0.62);
    color: #ffe600;
}

.btn-clear-filter,
.btn-clear-filter:hover {
    padding: 0;
    color: #27acaa;
    font-size: 14px;
    font-weight: bold;
}

.btn:disabled {
    pointer-events: none;
}

/*Common Modal Styling*/
.modal-content {
    background-color: #2e2e38;
    color: #fff;
    border: 2px solid #333;
    border-radius: 0;
}

.modal-header {
    color: #ffe600;
    border-bottom: 2px solid #333;
    background-color: rgba(0, 0, 0, 0.16);
    border-radius: 0;
}

.modal-body {
    background-color: rgba(0, 0, 0, 0.41);
}

.modal-footer {
    border-top: 0;
    background-color: rgba(0, 0, 0, 0.41);
    padding: 12px 15px;
}

/*Breadcrumb Styles*/
.custom-bread-crumb {
    padding: 0 !important;
}

.custom-bread-crumb .line {
    padding: 0 3px !important;
    color: #4ebeeb !important;
}

.custom-bread-crumb li a:hover {
    text-decoration: none;
    color: #4ebeeb;
}

.custom-bread-crumb span:nth-child(3),
.custom-bread-crumb span:nth-child(4),
.custom-bread-crumb span:nth-child(5) {
    text-transform: uppercase;
}

.last-cap span:last-child {
    text-transform: capitalize !important;
}

/*Datepicker*/
.ui-datepicker .ui-datepicker-group {
    color: #c4c4cd;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next,
.ui-datepicker td>span,
.ui-datepicker td>a {
    cursor: pointer;
}

.ui-datepicker .ui-datepicker-title select {
    background-color: rgba(51, 51, 51, 0.63);
    color: #c4c4cd;
    border: none;
    padding: 5px;
}

.ui-datepicker .ui-datepicker-title select option,
select option {
    background-color: #333;
    color: #c4c4cd;
}

select:focus {
    background-color: transparent !important;
    border: 1px solid rgba(196, 196, 205, 0.28) !important;
    color: #27acaa !important;
    border-radius: 0 !important;
}

.ui-calendar.ui-calendar-w-btn input {
    background-color: #000;
    border: 2px solid rgba(204, 204, 204, 0.36);
    box-shadow: none;
    color: #c4c4cd;
    font-size: 13px;
    cursor: pointer;
    padding: 12px;
    padding-left: 45px;
    border-radius: 40px;
}

.ui-datepicker-trigger.ui-calendar-button {
    border: 0;
    background-color: #ffe600;
    color: #000;
    font-size: 20px;
    position: absolute;
    left: 8px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    top: 8px;
}

.ui-calendar .ui-datepicker {
    min-width: 100%;
    background-color: #2e2e38;
    color: #c4c4cd;
    font-size: 13px;
    cursor: pointer;
}

.ui-datepicker-current-day>a {
    color: #ffe600 !important;
    font-size: 14px;
}

.material-styling .group .ui-calendar.ui-calendar-w-btn {
    width: 100%;
}

.material-styling .group .ui-datepicker-trigger.ui-calendar-button {
    left: auto !important;
    right: 0 !important;
    top: 2px !important;
}

/*Table Styles*/
/* .table-irr .ui-treetable-scrollable-header, .table-irr .ui-table-scrollable-header{
  background-color: rgba(0, 0, 0, 0.36);
  border-right: 1px solid rgba(116, 116, 128, 0.25);
} */
.table-irr.filter-table .ui-selectable-row.ui-state-highlight>td {
    border: 2px solid rgba(39, 172, 170, 0.58);
    border-left: 1px solid rgba(116, 116, 128, 0.25);
    border-right: 1px solid rgba(116, 116, 128, 0.25);
}

.table-irr th:focus,
.table-irr tr:focus,
.table-irr td:focus {
    outline: 0
}

.table-irr table {
    border: 1px solid rgba(116, 116, 128, 0.25);
}

.table-irr thead tr th {
    background-color: rgba(0, 0, 0, 0.36);
    border-bottom: 1px solid rgba(116, 116, 128, 0.25);
    border-top: 1px solid rgba(116, 116, 128, 0.25);
    font-size: 14px;
    border-right: 1px solid rgba(116, 116, 128, 0.25);
    vertical-align: middle;
    color: #27acaa;
    padding: 10px 8px !important;
    line-height: 1.2;
}

.table-irr thead tr th:last-child {
    border-right: none;
}

.table-irr tbody td {
    font-size: 13px;
    border-top: 1px solid rgba(116, 116, 128, 0.25);
    border-right: 1px solid rgba(116, 116, 128, 0.25);
    vertical-align: middle;
    padding: 10px 8px !important;
    position: relative;
    color: #ffffff;
    word-break: break-word;
    word-break: break-all;
}

.table-irr .ui-treetable-toggler {
    width: 23px;
    height: 23px;
}

.table-irr .ui-treetable-toggler>i.pi {
    color: #4ebbeb;
    position: relative;
    top: 4px;
    font-size: 17px;
}

.table-irr .ui-chkbox-box {
    background-color: rgba(0, 0, 0, 0.58);
    border-radius: 50%;
    width: 23px;
    height: 23px;
}

.table-irr .ui-chkbox-box .ui-chkbox-icon::before {
    color: #ffe600;
    position: relative;
    top: 2px;
    font-size: 17px;
}

.table-irr .ui-chkbox-icon.ui-clickable.pi.pi-minus {
    display: none;
}

.table-irr .ui-paginator-bottom {
    background-color: rgba(0, 0, 0, 0.26);
    padding: 10px;
    color: #c4c4cd;
    border: 1px solid rgba(196, 196, 205, 0.19);
    border-top: none;
}

.table-irr th>input[type="text"] {
    background-color: transparent;
    border: 1px solid rgba(197, 197, 206, 0.1);
    padding: 8px;
    color: #c4c4cd;
}

.table-irr .ui-table-caption {
    text-align: left;
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.23);
}

/*Status Table Styles*/
.status-progress-container>span {
    color: #747480;
}

.table-irr .status-progress div.circle-status {
    width: 45px;
    border: 2px solid rgba(116, 116, 128, 0.28);
    color: #747480;
    border-radius: 50%;
    height: 45px;
    margin: auto;
    z-index: 2;
    background-color: #333;
    margin-bottom: 5px;
}

.table-irr .status-progress hr {
    position: absolute;
    z-index: 1;
    border-top: 2px solid rgba(116, 116, 128, 0.41);
    width: 74%;
    top: 7px;
    right: 18%;
    display: none;
}

.table-irr .status-progress-container {
    text-align: center;
    word-break: break-word;
    line-height: 1.2;
}

.table-irr .status-progress .status-progress-container.completed .circle-status {
    border: 2px solid rgba(45, 183, 87, 1);
    color: #2db757;
}

.table-irr .status-progress .status-progress-container.completed>span {
    color: rgba(45, 183, 87, 1);
}

.table-irr .status-progress .status-progress-container.failed .circle-status,
.table-irr .status-progress .status-progress-container.not-applicable .circle-status {
    border: 2px solid rgba(255, 65, 54, 1);
    color: #ff4136;
}

.table-irr .status-progress .status-progress-container.failed>span,
.table-irr .status-progress .status-progress-container.not-applicable>span {
    color: rgba(255, 65, 54, 1);
}

.table-irr .status-progress .status-progress-container.in-progress .circle-status {
    border: 2px solid rgba(201, 129, 178, 1);
    color: #c981b2;
}

.table-irr .status-progress .status-progress-container.in-progress>span {
    color: rgba(201, 129, 178, 1);
}

/* .table-irr i.fas.fa-file-download{
  position: absolute;
  right: 15px;
  top: 36%;
  font-size: 20px;
  cursor: pointer;
} */
.table-irr .disabled-view {
    color: #747480;
    pointer-events: none;
}

.table-irr .failed-icon {
    color: #ff4136;
    cursor: pointer;
}

table .ui-calendar.ui-calendar-w-btn input {
    background-color: transparent !important;
    border: 1px solid rgba(197, 197, 206, 0.1) !important;
    border-radius: 0 !important;
    font-size: inherit !important;
    width: 100% !important;
    height: 34px;
    padding: 8px !important;
    padding-right: 25px !important;
}

table .ui-datepicker-trigger.ui-calendar-button {
    left: auto !important;
    right: 8px !important;
    background-color: transparent !important;
    width: 14px !important;
    height: auto !important;
    border-radius: 0 !important;
    color: #c4c4cd !important;
    top: 0 !important;
}

table .ui-calendar.ui-calendar-w-btn {
    width: 100%;
}

body>.ui-datepicker {
    z-index: 9999 !important;
    background-color: #000;
}

body>.ui-datepicker-header {
    background-color: rgba(51, 51, 51, 0.45);
    color: #c4c4cd;
    font-size: 13px;
}

body>.ui-datepicker th {
    font-size: 12px;
    color: rgba(196, 196, 205, 0.49);
    font-weight: normal;
}

body>.ui-datepicker td {
    font-size: 12px !important;
    padding: 0 !important;
}

/* .ui-state-highlight, .ui-state-highlight > td{
  border-bottom: 1px solid #27acaa !important;
  outline: 0;
  border-top: 1px solid #27acaa !important;
} */
.static-filter-value {
    background-color: rgb(0, 0, 0, 0.31);
    color: #c4c4cd;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 14px;
    border: 1px solid rgba(116, 116, 128, 0.37);
}

.static-filter-value .value {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
}

.static-filter-value .title {
    font-size: 12px;
    color: rgba(196, 196, 205, 0.49);
}

.table-irr .ui-treetable-caption {
    background-color: transparent;
    border: none;
    border-bottom: 0;
    color: #27acaa;
    font-size: 14px;
    padding: 10px;
    text-align: left;
}

.table-irr .ui-paginator-page.ui-state-active {
    background-color: #27acaa;
    color: #000 !important;
}

.rowFilter-table .ui-table-summary,
.columnFilter-table .ui-table-summary {
    background-color: rgba(0, 0, 0, 0.36);
    padding: 8px 10px;
    border: 1px solid rgba(204, 204, 204, 0.19);
    text-align: left;
}

/*General Badge Styling*/
.badge {
    padding: 8px 10px;
    font-weight: normal;
    color: #fff;
}

.badge.badge-fail {
    background-color: #ff4136;
}

.badge.badge-completed {
    background-color: #2db757;
}

.badge.badge-not-started {
    background-color: #000;
}

.badge.badge-in-progress {
    background-color: #c981b2;
}

/*Form Design*/
.material-styling .group {
    position: relative;
    margin-bottom: 40px;
}

.material-styling .group input,
.material-styling .group textarea {
    padding: 10px 10px 10px 0;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid rgba(196, 196, 205, 0.2);
    background-color: transparent;
    color: #fff;
    border-radius: 0;
}

.material-styling .group input:focus,
.material-styling .group textarea:focus {
    outline: none;
}

/* Label Design */
.material-styling .group label {
    color: #999;
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: -10px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

/* Active state */
.material-styling .group input:focus~label,
.material-styling .group input:valid~label,
.material-styling .group textarea:focus~label,
.material-styling .group textarea:valid~label {
    top: -10px;
    font-size: 14px;
    color: #27acaa;
    left: 0;
}

/* Underlining Bars */
.material-styling .group .bar {
    position: relative;
    display: block;
    width: 100%;
}

.material-styling .group .bar:before,
.material-styling .group .bar:after {
    content: '';
    height: 1px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #27acaa;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.material-styling .group .bar:before {
    left: 50%;
}

.material-styling .group .bar:after {
    right: 50%;
}

/* active state */
.material-styling .group input:focus~.bar:before,
.material-styling .group input:focus~.bar:after,
.material-styling .group textarea:focus~.bar:before,
.material-styling .group textarea:focus~.bar:after {
    width: 50%;
}

/* Highlighter */
.material-styling .group .highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
}

/* active state */
.material-styling .group input:focus~.highlight,
.material-styling .group textarea:focus~.highlight {
    -webkit-animation: inputHighlighter 0.3s ease;
    -moz-animation: inputHighlighter 0.3s ease;
    animation: inputHighlighter 0.3s ease;
}

.search-styling {
    background-color: transparent;
    border-radius: 40px;
    border: 1px solid #c4c4cd;
}

.dropdown-toggle::after {
    margin-left: 1em;
}

/** Animations **/
@-webkit-keyframes inputHighlighter {
    from {
        background: #5264AE;
    }

    to {
        width: 0;
        background: transparent;
    }
}

@-moz-keyframes inputHighlighter {
    from {
        background: #5264AE;
    }

    to {
        width: 0;
        background: transparent;
    }
}

@keyframes inputHighlighter {
    from {
        background: #5264AE;
    }

    to {
        width: 0;
        background: transparent;
    }
}

textarea {
    resize: none;
}

textarea:disabled,
input:disabled {
    cursor: not-allowed;
}

.non-input-label {
    top: -10px !important;
    font-size: 14px !important;
    color: #27acaa !important;
    left: 0 !important;
}

select.form-control {
    background-color: transparent;
    border: 1px solid rgba(196, 196, 205, 0.28);
    color: #27acaa;
    border-radius: 0;
}

.color-teal {
    color: #27acaa;
}

/*Tooltip and Popover*/
.popover {
    background-color: #27acaa;
}

.popover .popover-body {
    padding: 2px;
}

.tooltip-inner {
    max-width: 250px;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #27acaa;
    border-radius: .25rem;
    line-height: 1.2;
    max-height: 300px;
    overflow-y: scroll;
}

.bs-tooltip-right .arrow::before,
.bs-popover-right .arrow::after {
    border-right-color: #27acaa;
}

.bs-tooltip-top .arrow::before,
.bs-popover-top .arrow::after {
    border-top-color: #27acaa;
}

.bs-tooltip-left .arrow::before,
.bs-popover-left .arrow::after {
    border-left-color: #27acaa;
}

.bs-tooltip-bottom .arrow::before,
.bs-popover-bottom .arrow::after {
    border-bottom-color: #27acaa;
}

.pack-details-popover {
    background-color: #000;
    border: 1px solid rgba(196, 196, 205, 0.25);
    border-radius: 0;
}

.pack-details-popover .arrow::after {
    border-left-color: #000;
}

.pack-details-popover .popover-header {
    background-color: #2e2e38;
    color: #c4c4cd;
    border-bottom: 1px solid rgba(196, 196, 205, 0.2);
    text-align: center;
}

.pack-details-popover .popover-body {
    text-align: center;
    padding: 10px;
}

.pack-details-popover .popover-body .title {
    color: rgba(196, 196, 205, 0.61);
    font-size: 11px;
}

.pack-details-popover .popover-body .value {
    color: hsla(0, 0%, 100%, 0.74);
    font-weight: normal;
    font-size: 13px;
    margin-bottom: 15px;
}

.pack-details-popover .popover-body .value:last-child {
    margin-bottom: none;
}

/*Validation Styling*/
.alert-danger {
    color: #ff4136;
    background-color: transparent;
    border-color: transparent;
    padding: 5px 0;
    font-size: 13px;
}

.alert-info {
    color: #cecece !important;
    background-color: transparent;
    border-color: transparent;
    padding: 5px 0;
    font-size: 13px;
}

/*JQX Styling*/
.jqx-widget-content {
    border-color: rgba(116, 116, 128, 0.25) !important;
    background: transparent;
    font-family: 'EYInterstate', 'Noto Sans', sans-serif;
    font-size: 14px;
    font-weight: 700;
}

.jqx-widget-header {
    border-color: rgba(116, 116, 128, 0.25) !important;
    background: rgba(0, 0, 0, 0.13);
    color: #27acaa;
    font-weight: bold;
    border-left: 0;
    visibility: visible !important;
}

.sorticon.jqx-widget-header.jqx-widget-header-material {
    visibility: hidden !important;
}

.jqx-grid-cell {
    background: transparent;
    color: #c4c4cd !important;
    border-top: 1px solid rgba(116, 116, 128, 0.25) !important;
    border-right: 1px solid rgba(116, 116, 128, 0.25) !important;
    border-bottom: 0;
}

.jqx-grid-cell-alt {
    background: transparent;
}

.jqx-scrollbar-state-normal,
.jqx-grid-bottomright,
.jqx-panel-bottomright,
.jqx-listbox-bottomright {
    background: #333;
}

.jqx-fill-state-normal,
.jqx-fill-state-hover {
    border-color: transparent;
    background: rgba(0, 0, 0, 0.3);
}

.jqx-grid-cell-selected.jqx-grid-cell-edit {
    border-color: rgba(116, 116, 128, 0.25) !important;
    background-color: transparent !important;
    color: #fff !important;
    cursor: pointer;
}

.jqx-input-content.jqx-input-content-material {
    color: #c4c4cd !important;
    font-weight: normal !important;
}

.jqx-input.jqx-input-material {
    border: none !important;
}

/*Custom Tabs*/

.custom-tabs .nav-tabs {
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center !important;
}

.custom-tabs .nav-tabs .nav-item {
    margin-bottom: -1px;
    font-size: 14px;
    color: rgba(196, 196, 205, 0.92);
}

.custom-tabs .nav-tabs .nav-item .nav-link {
    border-radius: 0;
    border: 1px solid #747480;
    height: 50px;
    display: flex;
    align-items: center;
    color: #c4c4cd;
}

.custom-tabs .nav-tabs .nav-item .nav-link.active {
    background-color: transparent;
    border: 1px solid #ffe600;
    color: #fff;
}

/*Checkbox*/
.common-checkbox .ui-chkbox .ui-chkbox-box {
    background-color: #000;
    height: 30px;
    width: 30px;
    border: 1px solid rgba(196, 196, 205, 0.41);
    border-radius: 0;
}

.common-checkbox .ui-chkbox .ui-chkbox-icon {
    height: 86%;
    width: 100%;
    display: flex !important;
    align-items: center;
    color: #ffe600;
    justify-content: center;
    font-size: 23px;
}

.common-checkbox .ui-chkbox.ui-widget {
    margin-top: 1em;
}

/*Simplebar*/
.simplebar-track.simplebar-vertical {
    background-color: #333;
    border-radius: 20px;
    width: 5px;
}

.simplebar-track.simplebar-horizontal {
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 20px;
}

.simplebar-scrollbar:before {
    background: #747480;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    left: 0;
    right: 0;
}

.bootstrap-custom-dropdown .dropdown-menu.show {
    background-color: #000;
}

.bootstrap-custom-dropdown .dropdown-item {
    color: #c4c4cd;
    font-size: 12px;
}

.bootstrap-custom-dropdown .dropdown-item:hover {
    background-color: #333;
}

/*P-Dropdown Styling*/
.ui-dropdown-panel {
    background-color: #000;
    z-index: 2000 !important;
    color: #c4c4cd;
    font-size: 12px;
}

.ui-dropdown-panel .ui-dropdown-item {
    padding-bottom: 7px;
    padding-top: 7px;
}

.ui-dropdown-panel .ui-dropdown-item:hover {
    background-color: rgba(51, 51, 51, 0.49);
}

.ui-dropdown.ui-state-disabled {
    cursor: default !important;
    border: 1px solid #333;
}

/*Common text styling*/
.text-yellow {
    color: #ffe600;
    outline: 0;
}

.filter-adjustment {
    margin-top: 5rem;
}

.filter-adjustment .arrow {
    top: 20% !important;
}

.search-input-overall>input {
    background-color: rgba(0, 0, 0, 0.35);
    border: 0;
    color: #fff;
    border-radius: 20px;
    padding: 8px 15px;
    padding-right: 45px;
}

.search-input-overall>i {
    right: 15px;
    color: #999;
    top: 10px;
    position: absolute;
}

.btn-round-dark {
    background-color: rgba(0, 0, 0, 0.44);
    color: #999;
}

.btn-round-dark:hover {
    color: #ffe600;
}

/*Common Kendo Styling*/
.th .center-align {
    text-align: center;
}

.k-grid {
    background-color: transparent;
    color: #27acaa;
    border: 1px solid rgba(116, 116, 128, 0.25);
}

.k-grid-header {
    background-color: rgba(0, 0, 0, 0.25);
}

.k-grid-header .k-header {
    border-color: rgba(116, 116, 128, 0.25);
    color: #27acaa;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    line-height: 1.2;
}

.k-grid-content {
    background-color: transparent;
}

.k-grid td {
    border-top: 1px solid rgba(116, 116, 128, 0.25);
    border-right: 1px solid rgba(116, 116, 128, 0.25);
    text-align: center;
}

.k-grid tbody tr:hover,
.k-grid tbody tr.k-state-hover {
    background-color: transparent !important;
}

.k-grid tr.k-alt {
    background-color: transparent !important;
}

.k-grid .k-grid-header-wrap {
    border-right: 1px solid rgba(116, 116, 128, 0.25);
}

.k-grid-align-left {
    text-align: left !important;
}

.k-grid-align-right {
    text-align: right !important;
}

.k-grid-align-center {
    text-align: center !important;
}

.k-grid-toolbar {
    background-color: rgba(0, 0, 0, 0.36);
}

.k-grid-toolbar .k-button {
    color: #c4c4cd;
    background-color: #333;
    background-image: none;
}

.k-grid-edit-row td input {
    background-color: #000 !important;
    color: #fff !important;
}

.k-grid-pager {
    background-color: rgba(0, 0, 0, 0.26);
    padding: 5px 10px;
    color: #c4c4cd;
    border: 1px solid rgba(196, 196, 205, 0.19);
    border-top: none;
}

.k-pager-numbers .k-link {
    color: #c4c4cd;
    padding: 0;
    width: 24px;
    height: 24px;
    min-width: auto;
}

.k-pager-numbers .k-link.k-state-selected {
    color: #000;
    background-color: #27acaa;
}

.k-grid .k-numerictextbox .k-numeric-wrap .k-select {
    display: none;
}

.k-grid .k-grid-header-locked {
    border-color: transparent;
    color: #27acaa;
    background-color: transparent;
}

.k-grid .k-grid-content-locked td {
    border-color: rgba(116, 116, 128, 0.25);
    color: #27acaa;
    background-color: transparent;
}

.k-grid .k-grid-content-locked .k-state-hover td,
.k-grid .k-grid-content-locked tr:hover td,
.k-grid .k-grid-content-locked .k-alt td {
    background-color: transparent;
}

.k-grid .k-grid-content-locked {
    border-color: transparent;
}


@media (min-width: 1200px) {
    .modal-xl {
        max-width: 85%;
    }
}

@media(max-width:991px) {
    .modal-dialog {
        max-width: 95%;
    }
}

@media(max-width:1199px) {

    .ui-table-scrollable-body,
    .ui-treetable-scrollable-body {
        max-height: 100% !important;
    }
}

/* custom scroll*/
::-webkit-scrollbar {
    width: 1rem;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #656565c2;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}

/*primeng - multi select dropdown*/
@media(max-height: 41%) {
    .ui-multiselect-panel {
        max-width: 15.5%;
    }
}

.ui-multiselect-panel {
    background-color: #000 !important;
    width: 200px !important;
}

.ui-multiselect-panel .ui-multiselect-items-wrapper {
    color: #c4c4cd;
    font-weight: 100;
}

.ui-multiselect-header .ui-multiselect-close {
    color: #c4c4cd;
}

.ui-multiselect {
    width: 190px !important;
}

.ui-multiselect .ui-multiselect-label {
    color: #757575;
}

.ui-multiselect .ui-multiselect-trigger {
    color: #757575;
}

.ui-multiselect-panel .ui-multiselect-item {
    color: #c4c4cd;
}

.ui-multiselect-panel .ui-chkbox .ui-chkbox-box {
    background-color: #757575 !important;
    border-radius: 50% !important;
    width: 20px;
    height: 20px;
    color: #ffe600 !important;
    position: relative;
}

.ui-corner-all .ui-button-text-only{
padding: 0;
color: #27acaa;
font-size: 14px;
font-weight: 700;
background: none;
border: none;
}